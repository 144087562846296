<template>
  <div class="row">
    <div class="flex xs12">
      <h5 class="title">{{$t('reports.generate.data')}}</h5>
      <local-table
        class="table-hover table-striped"
        :columns="reportsFields"
        :data="reports"
        :top-options="[]"
        :loading="loading || isLoading"
      >
        <template v-slot:row-actions="{ props }">
          <va-popover
            :message="$t('tables.actions.testimonies')"
            placement="left"
          >
            <va-button
              flat
              small
              color="warning"
              icon="fa fa-quote-right"
              @click.prevent="showTestimonies(props.rowData)"
            />
          </va-popover>
          <va-popover
            :message="$t('tables.actions.images')"
            placement="left"
          >
            <va-button
              flat
              small
              color="primary"
              icon="fa fa-picture-o"
              @click.prevent="showImages(props.rowData)"
            />
          </va-popover>
        </template>
      </local-table>
    </div>
    <div class="flex xs12">
      <h5 class="title">{{$t('reports.generate.totals')}}</h5>
      <local-table
        class="table-hover table-striped"
        :columns="totalsFields"
        :data="totals"
        :loading="loading || isLoading"
      />
    </div>
    <div
      class="flex xs12 title"
      v-show="hasData"
    >
      <va-button
        color="success"
        @click="prepareDownload"
      >
        <text-loading
          :loading="loading || isLoading"
          icon="fa fa-file-excel-o"
        >
          {{ $t('tables.actions.export', {format: 'Excel'}) }}
        </text-loading>
      </va-button>
    </div>
    <va-modal
      v-model="testimoniesModal"
      :hide-default-actions="true"
      :title="$t('reports.modal.testimonies')"
      size="large"
    >
      <monthly-testimony :report="currentReport"></monthly-testimony>
    </va-modal>
    <va-modal
      v-model="imagesModal"
      :hide-default-actions="true"
      :title="$t('reports.modal.images')"
      size="large"
    >
      <presentation-images :report="currentReport"></presentation-images>
    </va-modal>
  </div>
</template>

<script>
const MonthlyTestimony = () => import(/* webpackPrefetch: true */ './Testimony')
const PresentationImages = () => import(/* webpackPrefetch: true */ '../presentations/Images')
const LocalTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/LocalTable')

export default {
  name: 'monthly-report',
  components: {
    LocalTable,
    MonthlyTestimony,
    PresentationImages,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    reportsList: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  computed: {
    reportsFields () {
      return [
        {
          name: 'district.country.name',
          title: this.$t('tables.headings.country'),
          visible: this.differentCountries(),
          callback: this.translatedName,
        },
        {
          name: 'proyection_date',
          title: this.$t('tables.headings.date'),
          callback: this.dateLabel,
        },
        {
          name: 'team.code',
          title: this.$t('tables.headings.code'),
        },
        {
          name: 'presentations_count',
          title: this.$t('tables.headings.presentations'),
          type: 'number',
          width: '150px',
        },
        {
          name: 'audience',
          title: this.$t('tables.headings.audience'),
          type: 'number',
          width: '150px',
        },
        {
          name: 'newcomers',
          title: this.$t('tables.headings.newcomers'),
          type: 'number',
          width: '150px',
        },
        {
          name: 'regulars',
          title: this.$t('tables.headings.regulars'),
          type: 'number',
          width: '150px',
        },
        {
          name: 'type_name',
          title: this.$t('tables.headings.preaching_point'),
        },
        {
          name: 'type_asistance',
          title: this.$t('tables.headings.preaching_asistance'),
          type: 'number',
          width: '150px',
        },
        {
          name: 'locations',
          title: this.$t('tables.headings.location'),
        },
        {
          name: 'type_district',
          title: this.$t('tables.headings.district'),
          sortField: 'type_district',
          callback: this.translatedName,
        },
        {
          name: 'type_minister',
          title: this.$t('tables.headings.assigned_leader'),
        },
        {
          name: 'complete',
          title: this.$t('tables.headings.complete_report'),
          callback: this.completeFormatFn,
          width: '150px',
        },
        {
          name: '__slot:actions',
          visible: this.reports.length > 0,
          dataClass: 'text-right',
          width: '50px',
        },
      ]
    },
    totalsFields () {
      return [
        {
          name: 'name',
          title: this.$t('tables.headings.country'),
        },
        {
          name: 'teams',
          title: this.$t('tables.headings.totals.teams'),
          type: 'number',
          width: '150px',
        },
        {
          name: 'presentations',
          title: this.$t('tables.headings.totals.presentations'),
          type: 'number',
          width: '150px',
        },
        {
          name: 'audience',
          title: this.$t('tables.headings.totals.audience'),
          type: 'number',
          width: '150px',
        },
        {
          name: 'newcomers',
          title: this.$t('tables.headings.totals.newcomers'),
          type: 'number',
          width: '150px',
        },
        {
          name: 'regulars',
          title: this.$t('tables.headings.totals.regulars'),
          type: 'number',
          width: '150px',
        },
        {
          name: 'preaching_attendance',
          title: this.$t('tables.headings.totals.preaching_asistance'),
          type: 'number',
          width: '150px',
        },
        {
          name: 'complete',
          title: this.$t('tables.headings.totals.complete_report'),
          type: 'number',
          width: '150px',
        },
      ]
    },
    hasData () {
      return Array.isArray(this.reports) && this.reports.length > 0
    },
  },
  data () {
    return {
      isLoading: false,
      testimoniesModal: false,
      imagesModal: false,
      currentReport: {},
      reports: [],
      totals: [],
      groupOptions: {
        enabled: true,
      },
    }
  },
  watch: {
    reportsList (val) {
      this.fixData(val)
    },
  },
  methods: {
    translatedName (name) {
      return this.$t(name)
    },
    completeFormatFn (completed) {
      let label = 'layout.no'
      if (completed) {
        label = 'layout.yes'
      }
      return this.$t(label)
    },
    dateLabel (date) {
      return this.$date.format(date, 'MMMM - yyyy')
    },
    fixData (reports) {
      const r = reports.slice(0)
      for (const report of r) {
        let audience = 0
        let newcomers = 0
        let regulars = 0
        let locations = ''

        report.presentations.forEach(p => {
          if (Number.isInteger(p.audience)) {
            audience += p.audience
          }
          if (Number.isInteger(p.newcomers)) {
            newcomers += p.newcomers
          }
          if (Number.isInteger(p.regulars)) {
            regulars += p.regulars
          }
          if (locations.indexOf(p.location) < 0) {
            if (locations !== '') {
              locations += ', '
            }
            locations += p.location
          }
        })

        report.audience = audience
        report.newcomers = newcomers
        report.regulars = regulars
        report.presentations_count = report.presentations.length

        // PP
        report.type_name = ''
        report.type_asistance = 0
        report.preaching_points.forEach(p => {
          if (report.type_name !== '') {
            report.type_name += ', '
          }
          report.type_name += p.name
          report.type_asistance += p.assistance_adults
          report.type_asistance += p.assistance_kids
          report.type_asistance += p.assistance_youngs
        })
        report.locations = locations

        const keys = Object.keys(report)
        let complete = true
        for (const k of keys) {
          if (report[k] === null) {
            complete = false
            break
          }
        }
        report.complete = complete
      }

      this.reports = r.slice(0)
      this.getTotals()
    },
    differentCountries () {
      if (this.reports.length === 0) {
        return false
      }

      const first = this.reports[0]
      return this.reports.some(r => r.district.country.name !== first.district.country.name)
    },
    showTestimonies (data) {
      this.currentReport = Object.assign({}, data)
      this.testimoniesModal = true
    },
    showImages (data) {
      this.currentReport = Object.assign({}, data)
      this.imagesModal = true
    },
    getTotals () {
      let countries = this.reports.map(r => r.district.country.name)
      countries = [...new Set(countries)]

      const totals = []
      for (const c of countries) {
        const current = {
          name: this.$t(c),
          teams: 0,
          presentations: 0,
          audience: 0,
          newcomers: 0,
          regulars: 0,
          preaching_attendance: 0,
          complete: 0,
        }
        for (const r of this.reports) {
          if (r.district.country.name !== c) {
            continue
          }

          current.teams++
          current.regulars += r.regulars
          current.audience += r.audience
          current.newcomers += r.newcomers
          current.presentations += r.presentations_count
          current.preaching_attendance += r.type_asistance
          if (r.complete) {
            current.complete++
          }
        }

        totals.push(current)
      }
      this.totals = totals.slice(0)
    },
    prepareDownload () {
      this.$emit('export')
    },
  },
}
</script>
<style scoped>
.title {
  padding-top: 15px;
}
</style>
